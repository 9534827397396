import React from 'react';
import { ACTIVITY_TYPES } from 'state-domains/constants';
import { AsyncState } from 'state-domains/types';
import { MapLayer } from 'src/components/Drillhole/DrillholeMap/DrillholeMap.types';
import { TableView } from 'state-domains/domain/subscription';

import { DrillholeCoordinates, FileObject } from '../drillhole/types';
import { MXDTimestamp } from '../utils';

export interface ProjectState extends AsyncState {
    projects: Record<string, Project>;
    allProjects: Record<string, Project>;
    projectFileGroups: Record<string, ProjectFileGroup>;
    projectLoadState: AsyncState;
    searchTerm?: string;
    pendingProjectId?: string;
    offset?: number;
    limit?: number;
    pinProjectState: AsyncState;
    mapType: MapLayer;
    overviewMapCollars: OverviewMapCollarState;
    importCollars: AsyncState;
}

export interface OverviewMapCollarState extends AsyncState {
    items: Record<string, OverviewCollar>;
    count: number;
}

export interface ModuleAction {
    access: boolean;
    action: string;
    code: string;
    description: string;
    id: string;
    index: number;
    model: string;
}

export interface UserPermissions {
    access: boolean;
    id: string;
    moduleActions: ModuleAction[];
    name: string;
}

export interface ActivityUser {
    userPermissions?: Record<string, UserPermissions>;
    fullAccess?: boolean;
    user: string;
    createdBy?: string;
    createdAt?: MXDTimestamp;
    id: string;
}

export type ActivityUsers = Record<string, ActivityUser>;

export interface GroupUser {
    user: string;
    id: string;
    createdBy?: string;
    createdAt?: MXDTimestamp;
}

export type GroupUsers = Record<string, GroupUser>;

export interface Group {
    id: string;
    users: GroupUsers;
}

export type Groups = Record<string, Group>;

export interface Activity {
    activity: string;
    id: string;
    index: number;
    users: ActivityUsers;
    createdAt?: MXDTimestamp;
    createdBy?: string;
    updatedAt?: MXDTimestamp;
    updatedBy?: string;
    groups: Groups;
    autoGenerateHoleNumbers?: boolean;
}

export type Activities = Record<string, Activity>;

export interface ProjectUser {
    user: string;
    id: string;
    createdBy?: string;
    createdAt?: MXDTimestamp;
    userPermissions?: Record<string, UserPermissions>;
}

export type ProjectUsers = Record<string, ProjectUser>;

export interface Project {
    id: string;
    published: boolean;
    activities: Activities;
    description?: string;
    boundary: ProjectBoundaryData | null;
    name: string;
    users: ProjectUsers;
    createdAt: MXDTimestamp;
    isvalid: boolean;
    createdBy?: string;
    updatedBy?: string;
    updatedAt?: MXDTimestamp;
    location?: string;
    thumbnail?: string;
    active?: boolean;
    pinned?: boolean;
    enablePinned?: boolean;
}

export interface ProjectFileGroup {
    id: string;
    project: string;
    files: Record<string, FileObject>;
    createdBy?: string;
    createdAt?: MXDTimestamp;
    updatedBy?: string;
    updatedAt?: MXDTimestamp;
}

export interface Projects {
    Project: Project[];
}

export interface ProjectError {
    error: Error;
}

// response from api
export interface ProjectBoundaryData {
    type: string;
    features: ProjectBoundaryFeature[];
}

export interface ProjectBoundaryFeature {
    type: string;
    geometry: BoundaryGeometry;
    properties: BoundaryProperties;
}

export interface BoundaryGeometry {
    type: string;
    coordinates: number[][][];
}
export interface BoundaryProperties {
    name: string;
    isSaved: boolean;
}

// friendly form
export interface ProjectBoundary {
    name: string;
    coordinates: number[][] | number[][][];
}

export interface TableList {
    tableListOptions: string[];
    tableListPermissions: Record<string, { status: string; reason: Record<string, any> }>;
}

export type ActivityTableList = Record<string, TableList>;

export interface MapTypes {
    mapType: MapLayer;
}

export interface OverviewCollar {
    id: string;
    name: string;
    geoJsonLocation: number[][];
    type: ACTIVITY_TYPES;
    wgs84: DrillholeCoordinates;
}

export interface OverviewCollarsResponse {
    data: OverviewCollar[];
}

export interface ProjectActivityPermission {
    permissions: number;
    type: ACTIVITY_TYPES;
    name: string;
}

export type ProjectActivityPermissions = Record<string, ProjectActivityPermission>;

export interface TableInfo
    extends Pick<
        TableView,
        | 'allowDupes'
        | 'allowGaps'
        | 'allowOverlaps'
        | 'linkedToLithology'
        | 'lithologyTableView'
        | 'intervalType'
    > {
    id: string;
    name: string;
    label: string;
    isEditable: boolean;
    tableViewId: string;
    reasonObject: { reasons: string[]; params: any };
    specialTables: string[];
    rowReference?: string;
    subTableInfo?: { id: string; isEditable: boolean };
    noAccess?: boolean;
    special?: string;
    activityId?: string;
    tablesInTableView?: Record<string, TableInfo>;
    isIncludedInTablesInTableView?: boolean;
    sampleResultOptions?: Record<string, boolean>;
    sampleDispatchOptions?: Record<string, boolean>;
}

export interface SelectedProjectContextValue {
    projectId: string;
    project: Project;
    projectActivityPermissions: ProjectActivityPermissions;
    tableListInfo: Record<string, Record<string, TableInfo>>;
    type: ACTIVITY_TYPES;
}

export const SelectedProjectContext = React.createContext<SelectedProjectContextValue>({
    project: {} as Project,
    projectId: '',
    projectActivityPermissions: {},
    tableListInfo: {},
    type: ACTIVITY_TYPES.DRILLING,
});

export enum PROJECT_ACTIVITY_PERMISSIONS {
    ACTIVITY_FEED = 1,
    PROJECT_SUMMARY = 2,
    SAMPLE_DISPATCH = 4,
    ASSAYS_QAQC = 8,
    EXPORT = 16,
    REPORTS = 32,
    COLLAR_CREATE = 64,
    COLLAR_DELETE = 128,
    COLLAR_UNASSIGN = 256,
    COLLAR_STATE = 512,
    COLLAR = 1024,
    IMPORT_STATE = 2048,
    IMPORT = 4096,
}

export interface ProjectInfoFlags {
    returnAllTables: boolean;
    tableInfoPermission?: PROJECT_ACTIVITY_PERMISSIONS;
}

export const DEFAULT_PROJECT_INFO_FLAGS: ProjectInfoFlags = {
    returnAllTables: false,
    tableInfoPermission: PROJECT_ACTIVITY_PERMISSIONS.COLLAR,
};
